<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">智能电控</h1>
        <p data-caption-delay="100" class="wow fadeInRight">适用于新能源动力域以及智能底盘域的各类在环仿真测试验证方案</p>
        <div class="solution-text-button wow fadeInUp"  @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="新能源三电HIL测试系统" name="1">新能源三电HIL测试系统</el-tab-pane>
          <el-tab-pane label="智能底盘HIL测试系统" name="2">智能底盘HIL测试系统</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text" style="width: 100%;">
                    <p>新能源三电系统HIL测试平台可以实现动力域控制器在虚拟环境中的仿真测试，包括整车控制器（VCU）、电机控制器（MCU）及电池管理系统（BMS）的仿真测试。验证三电系统的控制策略，提高三电系统的可靠性及安全性。</p>
                  </div>
                  <div class="intro-text" style="width: 100%;">
                    <p>新能源三电系统HIL测试平台主要实现新能源汽车整车模型的实时运行，模拟三电系统各被测控制器的输入信号，采集各控制器的输出和必要的输入信号，实现各控制器对实时模型的闭环控制，最终完成各控制器的功能测试和验证。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInRight" style="background: #E2EAF4">
          <div class="container">
            <div class="normal-title" style="width: 280px;">HIL测试平台架构原理图</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-tripleElectricHIL-architecture-pic.png" alt="">
              </div>
            </div>
          </div>
        </section>
        <section class="ethernet-solution-components wow fadeInLeft">
          <div class="container">
            <div class="normal-title">测试内容介绍</div>
            <div class="solution-components">
              <div class="components-content" >
                <div class="components-table" style="height: 220px;">
                  <el-table
                    style="font-size: 14px;"
                    :data="componentsTableData"
                    :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                    border
                    size="small"
                  >
                    <el-table-column prop="project" label="项目" width="150"/>
                    <el-table-column prop="description" label="描述" />
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <div class="normal-title">解决方案介绍</div>
                <div class="all-intro">
                  <div class="intro-text" style="width: 95%;">
                    <p>
                      汽车底盘是由传动系、行驶系、转向系及制动系组成的综合系统，智能底盘与ADAS&AD的发展相辅相成。随着底盘域电控化、智能化程度越来越高，底盘域HIL的作用和优势也越来越明显。
                      底盘域HIL 测试系统能够针对底盘相关控制器、传感器、执行器进行半实物仿真测试，目标是搭建底盘域控制器的闭环测试环境。基于成熟的实时平台、功能完备的车辆动力学模型搭建起目标车辆的虚拟仿真系统，
                      通过供电、I/O资源及总线资源将被测对象集成到虚拟仿真系统中，可以对具体工况下的功能、通讯、诊断、Failsafe等进行测试和验证。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                <div class="intro-picture">
                  <img src="../../../assets/images/solution-chassisHIL-intro.png" alt="" style="display: block;width: 100%;margin-top: 15px;">
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>成熟稳定的测试平台，多核并行，计算能力强</li>
                <li>I/O接口硬件丰富，平台拓展性好</li>
                <li>支持众多主流平台软件，兼容性好</li>
                <li>图形化的操作界面，人机交互好</li>
                <li>可同时进行试验管理及网络节点监控，综合性好</li>
                <li>测试用例编辑简单，自动生成测试报告，方便性好</li>
                <li>提供了ECU级、模块级、系统级方案，满足不同客户的需求</li>
                <li>丰富的模型开发能力，使虚拟车辆和被测对象实现了完美的融合</li>
                <li>执行台架可实现手动输入和自动化输入，针对不同测试工况灵活匹配</li>
                <li>可与ADAS相关功能相互配合，实现整车在环（VIL）的测试和验证</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="electric-solution-components network-solution-components chassisHIL-components wow fadeInLeft">
          <div class="container" style="height: 530px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <el-row class="components-content" :gutter="40">
                <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
                  <img src="../../../assets/images/solution-hil-components.png" alt="">
                </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
                  <div class="components-text">
                    <div>
                      <h1>实时平台</h1>
                      <p>Vector VT System、NI PXI等</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 59px'">
                      <h1>车辆动力学软件</h1>
                      <p>DYNA4或其他第三方同类软件</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
                      <h1>试验管理软件</h1>
                      <p>CANoe或其他第三方同类软件</p>
                    </div>
                    <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
                      <h1>自动化测试软件</h1>
                      <p>vTESTstudio或其他第三方同类软件</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight" style="height: 610px">
          <div class="container">
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="normal-title">应用领域</div>
                <div class="all-application" style="margin-top: 40px">
                  <p>适用于乘用车、商用车底盘涉及到的所有电控系统及零部件，包括但不限于下图所示：</p>
                  <div class="application-picture">
                    <img src="../../../assets/images/solution-chassisHIL-application-pic.png" alt="">
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="dyna-product-system-picture wow fadeInLeft">
          <div class="container">
            <div class="normal-title">系统图片</div>
            <div class="all-system-picture">
              <div class="system-picture">
                <img src="../../../assets/images/solution-chassisHIL-system-pic.jpeg" alt="">
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "ElectronicControl",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-4',
      tabFixed: false,
      activeProduct: '1',
      componentsTableData: [
        {
          project: "VCU HIL测试",
          description: "主要包含系统上下电测试，能量管理功能测试，扭矩分配功能测试，自定义驾驶循环工况测试，手动驾驶模拟功能测试等。",
        },
        {
          project: "MCU HIL测试",
          description: "主要包含相电流传感器信号、电机温度传感器信号及位置传感器信号采样测试，电机逆变器驱动功能测试，扭矩转速模式切换测试，扭矩响应及跟踪情况测试等。",
        },
        {
          project: "BMS HIL测试",
          description: "主要包含单体电池、电池包及高压回路上的电压、电流、温度采样测试，充电管理测试，高压互锁测试，绝缘电阻监测测试，SOC及SOH评估测试，均衡管理测试，热管理测试等。",
        },
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "智能电控 - 解决方案 - 东信创智";
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    }
  }
}
</script>

<style scoped>

</style>
